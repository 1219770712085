@import 'vars';

/** HEADER **/
.header {
  position: relative;
  background-color: $creamColor;
  padding-top: 20px;
  &__wrapper {
    display: grid;
    grid-template-columns: 295px 1fr;
    grid-gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1528px;
    height: 64px;
    margin: auto;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 224px 160px;
    align-items: center;
    grid-gap: 24px;
    transition: left 0.5s;
  }
  &__navigation {
    display: flex;
    justify-content: center;
  }
  &__logo {
    width: 100%;
    max-width: 295px;
    height: 64px;
  }
  &__burger {
    display: none;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-left: auto;
    width: 32px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  &__burger-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $orangeColor;
  }
  &__close {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 11px;
      content: '';
      height: 24px;
      width: 2px;
      background-color: $lightGrayColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
@media (max-width: 1560px) {
  .header {
    &__content {
      grid-template-columns: 1fr 184px 160px;
    }
    &__button-link {
      font-size: 12px;
      min-width: 184px;
      max-width: 184px;
      padding: 8px;
    }
  }
}
@media (max-width: 1439px) {
  .header {
    &__wrapper {
      grid-template-columns: 1fr 32px;
    }
    &__logo {
      position: relative;
      max-width: 256px;
      z-index: 70;
    }
    &__content {
      position: absolute;
      top: 0;
      left: -304px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 64px 120px;
      align-items: baseline;
      width: 100%;
      max-width: 304px;
      height: 100vh;
      background-color: $whiteColor;
      z-index: 60;
      &_state {
        &--open {
          left: 0;
          overflow-y: auto;
        }
      }
    }
    &__navigation {
      justify-content: flex-start;
      margin-top: 112px;
      padding: 0 24px;
    }
    &__button {
      padding: 0 24px;
    }
    &__contacts {
      padding: 0 24px;
    }
    &__burger {
      display: grid;
    }
    &__close {
      display: block;
    }
  }
}
/** HEADER END **/

/** LOGO **/
.logo {
  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__image {
    width: 100%;
    height: 100%;
  }
}
/** LOGO END **/

/** NAVBAR **/
.navbar {
  &__list {
    display: flex;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
  &__link {
    font-size: 14px;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
}
@media (max-width: 1560px) {
  .navbar {
    &__item {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
@media (max-width: 1439px) {
  .navbar {
    &__list {
      flex-flow: column wrap;
    }
    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
/** NAVBAR END **/

/** CONTACTS-INFO **/
.contacts-info {
  &__phone {
    font-weight: 500;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
  &__email {
    font-size: 14px;
    color: $orangeColor;
    text-decoration: underline;
    transition: font-weight 0.2s;
    &:hover {
      font-weight: 500;
    }
  }
}
/** CONTACTS-INFO END **/

/** ACADEMY-INFO **/
.academy-info {
  background-color: $creamColor;
  padding-top: 96px;
  &__wrapper {
    width: 100%;
    max-width: 1528px;
    margin: auto;
  }
  &__header {
    position: relative;
    text-align: center;
  }
  &__title {
    font-family: $elMessiri;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
  }
  &__sub-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 24px 0 48px;
  }
  &__items {
    display: flex;
    justify-content: center;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 48px;
      &:after {
        content: '';
        position: absolute;
        right: -28px;
        width: 8px;
        height: 8px;
        background-color: $darkGrayColor;
        border-radius: 20px;
      }
    }
  }
  &__banners {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 72px;
    padding-bottom: 136px;
    &:after {
      content: '';
      position: absolute;
      top: 64px;
      left: -112px;
      width: 100%;
      height: 601px;
      background: url(../images/academy_info_bg.png) center/cover no-repeat;
      z-index: 10;
    }
  }
  &__banner {
    z-index: 20;
    &_position {
      &--top {
        align-self: flex-start;
      }
      &--bottom {
        align-self: flex-end;
      }
    }
  }
  &__small-banner {
    width: 285px;
    height: 508px;
    &_margin {
      &--top {
        margin-top: 32px;
      }
      &--bottom {
        margin-bottom: 32px;
      }
    }
  }
  &__big-banner {
    width: 385px;
    height: 685px;
    &_margin {
      &--top {
        margin-top: 60px;
      }
      &--bottom {
        margin-bottom: 60px;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
  }
  &__star {
    position: absolute;
    &_size {
      &--big {
        top: 0;
        right: -40px;
      }
      &--small {
        top: -48px;
        left: 40px;
      }
    }
  }
}
@media (max-width: 1610px) {
  .academy-info {
    &__star {
      right: 0;
    }
  }
}
@media (max-width: 1439px) {
  .academy-info {
    &__banners {
      &:after {
        top: -64px;
        left: 0;
        height: 100%;
      }
    }
    &__small-banner, &__big-banner {
      height: auto;
    }
    &__image {
      object-fit: cover;
    }
  }
}
@media (max-width: 767px) {
  .academy-info {
    padding-top: 48px;
    &__title {
      font-size: 32px;
      line-height: 32px;
    }
    &__sub-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    &__items {
      flex-flow: column wrap;
      max-width: 320px;
      margin: auto;
    }
    &__item {
      &:not(:last-child) {
        margin-right: 0;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        width: 8px;
        height: 8px;
        background-color: $darkGrayColor;
        border-radius: 20px;
      }
    }
    &__text {
      font-size: 12px;
      text-align: left;
      padding-left: 24px;
    }
    &__banners {
      margin-top: 32px;
      padding-bottom: 32px;
    }
  }
}
/** ACADEMY-INFO END **/

/** TOWNS **/
.towns {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 848px;
  }
  &__map {
    width: 100%;
    height: 100%;
  }
  &__selection {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 848px;
  }
  &__content {
    padding: 72px 64px 144px;
  }
  &__title {
    font-family: $elMessiri;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
  }
  &__form {
    margin: 40px 0 48px;
  }
  &__items {
    display: grid;
    grid-gap: 24px 48px;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3), 1fr));
  }
  &__link {
    font-size: 14px;
    text-transform: uppercase;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
  &__star {
    position: absolute;
    top: 120px;
    right: 64px;
  }
}
@media (max-width: 1439px) {
  .towns {
    &__wrapper {
      grid-template-columns: 1fr 1fr;
    }
    &__content {
      padding: 48px 24px 24px;
    }
    &__items {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &__title {
      font-size: 32px;
      line-height: 32px;
    }
  }
}
@media (max-width: 767px) {
  .towns {
    &__wrapper {
      grid-template-columns: 1fr;
    }
    &__map {
      width: 100%;
      height: 320px;
      order: 2;
    }
    &__selection {
      order: 1;
    }
    &__content {
      padding: 32px 16px;
    }
    &__form {
      margin: 16px 0 24px;
    }
  }
}
/** TOWNS END **/

/** TOWN SELECT **/
.towns-form {
  &__label {
    margin-bottom: 24px;
  }
  &__input {
    max-width: 408px;
  }
}
/** TOWN SELECT END **/

/** FOOTER **/
.footer {
  background-color: $creamColor;
  padding: 32px 0;
  &__wrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 544px 280px 160px 1fr;
    align-items: center;
    width: 100%;
    max-width: 1528px;
    height: 80px;
    margin: auto;
  }
  &__logo {
    width: 352px;
    height: 80px;
  }
  &__copyright {
    width: 392px;
    height: 40px;
    margin-left: auto;
  }
  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $darkGrayColor;
  }
}
@media (max-width: 1439px) {
  .footer {
    &__wrapper {
      grid-template-columns: 295px 184px 160px 1fr;
      grid-gap: 16px;
    }
    &__logo {
      width: 100%;
      max-width: 295px;
    }
    &__button-link {
      font-size: 12px;
      min-width: 184px;
      max-width: 184px;
      padding: 8px;
    }
  }
}
@media (max-width: 1250px) {
  .footer {
    &__wrapper {
      grid-template-columns: 1fr 1fr 160px;
      height: auto;
    }
    &__copyright {
      grid-column-start: 1;
      grid-column-end: 5;
      width: 100%;
      text-align: center;
    }
  }
}
@media (max-width: 767px) {
  .footer {
    &__wrapper {
      grid-template-columns: 1fr;
    }
    &__logo {
      margin: auto;
    }
    &__button {
      display: flex;
      justify-content: center;
    }
    &__contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }
    &__contact-phone {
      margin-left: auto;
    }
    &__copyright {
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
}
@media (max-width: 374px) {
  .footer {
    &__contacts {
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
    &__contact-phone {
      margin: auto;
    }
    &__contact-emails {
      text-align: center;
    }
    &__copyright {
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
}
/** FOOTER END **/